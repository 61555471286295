exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-statement-js": () => import("./../../../src/pages/accessibility-statement.js" /* webpackChunkName: "component---src-pages-accessibility-statement-js" */),
  "component---src-pages-become-a-partner-js": () => import("./../../../src/pages/become-a-partner.js" /* webpackChunkName: "component---src-pages-become-a-partner-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-request-a-demo-js": () => import("./../../../src/pages/request-a-demo.js" /* webpackChunkName: "component---src-pages-request-a-demo-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-starters-js": () => import("./../../../src/pages/starters.js" /* webpackChunkName: "component---src-pages-starters-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-flotiq-comparison-landing-page-js": () => import("./../../../src/templates/flotiq-comparison-landing-page.js" /* webpackChunkName: "component---src-templates-flotiq-comparison-landing-page-js" */),
  "component---src-templates-integration-page-js": () => import("./../../../src/templates/integration-page.js" /* webpackChunkName: "component---src-templates-integration-page-js" */),
  "component---src-templates-integrations-page-js": () => import("./../../../src/templates/integrations-page.js" /* webpackChunkName: "component---src-templates-integrations-page-js" */),
  "component---src-templates-starter-js": () => import("./../../../src/templates/starter.js" /* webpackChunkName: "component---src-templates-starter-js" */)
}

